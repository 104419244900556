<template>
	<div class="auth-page">
		<div class="content">
			<div class="header">
					<div class="logo">
						<img src="../assets/images/logo.png" alt="logo" />
					</div>
			</div>
			<div class="data-container">
				<div class="left-content">
					<div class="login-text">
						<h3>
							<span>A private virtual Room</span> <span>to play with </span>
							<span> family &#38; friends</span>
						</h3>
						<p>
							<span>See</span> | <span>Talk</span> | <span>Play</span> |
							<span>Feel</span>
						</p>
					</div>					
					<b-btn class="gold-button" to="/login">Signin</b-btn>
				</div>
				<div class="right-content">
					<div class="auth-card">
						<p class="heading">Register</p>
					
						<input type="text"
							class="input-box"
							v-model.trim="userdisplayname"
							placeholder="user displayname"
							name="userdisplayname" />
						<input type="text"
							class="input-box"
							v-model.trim="email"
							placeholder="Email"
							name="email" />
						

						<div class="buttons">
							<!--<b-btn class="game-button" @click="register">Register</b-btn>-->
							<processingbutton buttonclass="game-button" label="Signup" :onclick="register" :isprocessing="isprocessing" />
						</div>
					
						<p class="register">
							Existing user
							<router-link class="link-button" to="/login">Login here</router-link>
						</p>
						<p class="register">
							Have an invitation code?
							<router-link class="link-button" to="/joingame">Join with code</router-link>
						</p>
					</div>
				</div>
			</div>
			<div class="auth-footer">
				<div class="footer-link text-muted font-weight-light">
					<a target="_blank" href="/privacy">Privacy</a> |
					<a target="_blank" href="/terms">Terms of use</a> |
					<a target="_blanK" HREF="/howto">How to</a>
				</div>
				<p>© 2021 ZoomGameZ. All rights reserved.</p>
				<span id="siteseal"></span>
			</div>
		</div>
	</div>
</template>
<script lang="js">
	import { mapState } from 'vuex'
	import DEBUG from '@/common/DEBUG'
	//import authenticator from './components/oauthauthenticator'
	import processingbutton from '@/components/processingbutton'

	export default {
		name: 'register',

		components: {
			processingbutton
		},

		data() {
			return {
				userdisplayname: '',
				email: '',
				phonenumber: '',
				password: '',
				confirmpassword: '',
			}
		},

		computed: {
			...mapState(
				['isprocessing', 'haserror', 'error']
			),
		},

		methods: {
			async register() {
				const that = this

				const email = that.email
				const password = 'hello!' // that.password
				const confirmpassword = that.confirmpassword
				const userdisplayname = that.userdisplayname
				const phonenumber = '+910000000000' //that.phonenumber

				if (email == '' || userdisplayname == '') {
					alert('all values are required. please fill them and try again')
					return
				}

				try {
					
					await that.$store.dispatch('register', { userdisplayname, email, phonenumber, password });
					await that.$router.push({ name: 'login' })
					await that.notifySuccess(`Sign-up successful, check your email to complete sign-up`)
				}
				catch (error) {
					DEBUG.log('ERROR in register - %o', error)
					that.notifyError(`Unable to signup due to an error - ${error.message}`)
				}
			},
		}
	}
</script>